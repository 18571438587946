@charset "utf-8";

html {
  height: 100%;
  font-family: $sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  line-height: 1.8;
  color: $c-text;
  word-wrap: break-word;
}

body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-size(1.5);
  background-color: #FFF;
  @include media(pc) {
    min-width: 1000px;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border: none;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  &:link {
    text-decoration: none;
    color: inherit;
    transition: all 0.15s ease-in-out;
  }
  &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  &[disabled] {
    color: #ccc;
    pointer-events: none;
  }
}

/* -- clearfix -- */
.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

/* -- visible device -- */
.visible-pc {
  @include media(pc) {
    display: block !important;
  }
  @include media(sp) {
    display: none !important;
  }
}

.visible-sp {
  @include media(pc) {
    display: none !important;
  }
  @include media(sp) {
    display: block !important;
  }
}

/* -- title -- */
.ttl {
  margin-bottom: 30px;
  @include font-size(2.8);
  @include media(sp) {
    @include font-size(2.6);
  }
}

/* -- text -- */
.txt {
  margin-bottom: 20px;
  line-height: 2.0;
  @include font-size(1.6);
}

.txt-note {
  position: relative;
  padding-left: 1.1em;
  color: #666;
  &:before {
    position: absolute;
    left: 0;
    content: "※";
  }
}

/* -- button -- */
.btn {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  text-align: center;
  @include font-size(1.8);
  border-radius: 3px;
  color: #FFF !important;
  background-color: $c-orange;
  box-sizing: border-box;
  @include media(sp) {
    width: 100%;
    padding: 0;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}


/*header
----------------------------------*/
.header {
  background-color: $c-orange;
  @include media(pc) {
    padding: 0 10px;
  }

  &--inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 990px;
    height: 60px;
    margin: 0 auto;
    @include media(sp) {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }

  &-logo {
    letter-spacing: -0.1rem;
    font-weight: bold;
    @include font-size(3.2);
    color: #FFF;
    @include media(sp) {
      @include font-size(2.6);
    }
    a {
      display: flex;
      align-items: center;
      color: inherit;
    }
    span {
      margin-right: 10px;
      letter-spacing: normal;
      font-weight: normal;
      @include font-size(1.8);
      @include media(sp) {
        margin-right: 5px;
        @include font-size(1.4);
      }
    }
  }

  nav {
    @include media(sp) {
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: $c-orange;
      opacity: 0;
      transition: opacity .4s ease, visibility .1s ease;
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    margin-right: 75px;
    @include media(sp) {
      display: block;
      position: absolute;
      top: 60px;
      width: 100%;
      margin-right: 0;
    }

    &__list {
      margin-left: 30px;
      @include font-size(1.8);
      color: #FFF;
      @include media(sp) {
        margin-left: 0;
        text-align: center;
        @include font-size(2.0);
      }
      a {
        color: inherit;
        @include media(sp) {
          display: block;
          width: 100%;
          padding: 10px 0;
          margin-top: 20px;
        }
      }
    }
  }
}

.is-open .header nav {
  visibility: visible;
  opacity: 1;
}
.is-open .header-inner {
  position: fixed;
}


/*header-toggle-btn
----------------------------------*/
#header-toggle-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 50px;
  z-index: 500;
  > div {
    position: relative;
    width: 30px;
    height: 18px;
    margin: 20px 0 0 10px;
  }
  span {
    width: 100%;
    height: 1px;
    left: 0;
    display: block;
    background: #fff;
    position: absolute;
    transition: transform .3s ease-in-out, top .2s ease;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 16px;
    }
  }
}

.is-open #header-toggle-btn {
  span {
    background: #fff;
    &:nth-child(1) {
      top: 15px;
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      top: 15px;
      width: 0;
      left: 50%;
    }
    &:nth-child(3) {
      top: 15px;
      transform: rotate(-45deg);
    }
  }
}


/*main
----------------------------------*/
.main {
  min-height: 570px;
  &--inner {
    width: 830px;
    margin: 0 auto;
    padding: 50px 0 80px;
    @include media(sp) {
      width: auto;
      padding: 25px 30px 40px;
    }
  }
}

.main-bg {
  background: transparent url(../img/bg_main.png) right bottom no-repeat;
  background-size: 100% auto;
}


/*footer
----------------------------------*/
.footer {
  //position: absolute;
  //bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: #FFD569;

  .copyright {
    text-align: center;
    @include font-size(1.2);
    color: #000;
  }
}


/*profile
----------------------------------*/
.talent-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 600px;
  @include media(sp) {
    width: 100%;
  }

  &__list {
    width: 50%;
    margin-bottom: 15px;
    @include font-size(2.0);
    &:before {
      display:inline-block;
      content: '';
      margin-right: 5px;
      @include triangle(right, 10px, 12px, $c-orange);
    }
    a {
      color: inherit;
    }
    span {
      margin-left: 1em;
      @include font-size(1.5);
    }
  }
}

.talent {
  margin-top: 50px;

  &__list {
    position: relative;
    display: flex;
    margin-top: 30px;
    padding-top: 50px;
    @include media(sp) {
      display: block;
    }

    &:before {
      content: "";
      background-image: linear-gradient(to right, #ccc, #ccc 2px, transparent 2px, transparent 4px);
      background-size: 6px 1px;
      background-repeat: repeat-x;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-img {
    width: 150px;
    margin-right: 50px;
    @include media(sp) {
      width: 200px;
      margin: 0 auto 30px;
    }

    img {
      border: 1px solid #eee;
    }
  }

  &-profile {
    width: 710px;
    @include media(sp) {
      width: 100%;
    }

    .name {
      margin-bottom: 20px;
      @include font-size(2.4);
      span {
        margin-left: 20px;
        @include font-size(1.6);
      }
    }

    .txt {
      line-height: 1.8;
      @include font-size(1.5);
      span {
        @include font-size(1.2);
      }
    }
  }
}

#page-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 50px;
  text-decoration: none;
  transform: rotate(90deg);
  font-size: 90%;
  line-height: 1.5rem;
  color: #333;
  padding: 0 0 0 35px;
  border-top: solid 1px $c-orange;
  @include media(sp) {
    right: 0;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 0px;
    width: 15px;
    border-top: solid 1px $c-orange;
    transform: rotate(35deg);
    transform-origin: left top;
  }

  &:hover {
    opacity: 1.0;
  }
}