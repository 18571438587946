@charset "utf-8";

//-----------------------------------
// 変数
//-----------------------------------

//フォントファミリー
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700&display=swap&subset=japanese");
$sans-serif: "Noto Sans JP", "Helvetica Neue", "游ゴシック", "Yu Gothic",
  "YuGothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro",
  "メイリオ", "Meiryo", "ＭＳ ゴシック", sans-serif;

//色
$c-orange: #F9B17B;

//文字色
$c-text: #333;
$c-text-link: #3D6DAC;
$c-text-error: #C00;

//線色
$c-border: #DDD;

//ベンダープレフィックス
$prefixList: -webkit-, -moz-, -ms-, -o-, null;

//-----------------------------------
// ブレイクポイント設定
//-----------------------------------
$bp-sp: 768px;
$bp-pc: 769px;

@mixin media($media-width) {
  @if $media-width == sp {
    @media only screen and (max-width: $bp-sp) {
      @content;
    }
  } @else if $media-width == pc {
    @media print, screen and (min-width: $bp-pc) {
      @content;
    }
  }
}

//-----------------------------------
// 文字サイズrem対応
//-----------------------------------
@mixin font-size($s: 1.4) {
  font-size: ($s * 10) + px;
  font-size: $s + rem;
}

@mixin line-height($s: 1.4) {
  line-height: ($s * 10) + px;
  line-height: $s + rem;
}

//-----------------------------------
// clearfix
//-----------------------------------
@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

//-----------------------------------
// hover透過
//-----------------------------------
@mixin hover-opacity($flag: true) {
  transition: all 0.15s ease;
  &:hover {
    @if $flag == true {
      opacity: 0.65;
    }
    @content;
  }
}

//-----------------------------------
// 親要素に対して中央寄せ
//-----------------------------------
@mixin center($direction) {
  position: absolute;
  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $direction==x {
    left: 50%;
    transform: translateX(-50%);
  } @else if $direction==y {
    top: 50%;
    transform: translateY(-50%);
  }
}

//-----------------------------------
// flexセット
//-----------------------------------
@mixin flex-set(
  $align: flex-start,
  $justy: flex-start,
  $dir: row,
  $wrap: nowrap
) {
  display: flex;
  align-items: $align;
  justify-content: $justy;
  flex-direction: $dir;
  flex-wrap: $wrap;
}

//-----------------------------------
// margin padding
//-----------------------------------
@for $i from -1000 through 1000 {
  // top
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  // right
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  // bottom
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  // left
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
}

//-----------------------------------
// width
//-----------------------------------
@for $i from -1000 through 1000 {
  .w-#{$i} {
    width: #{$i}px !important;
  }
}
.w-full {
  width: 100%;
}

//-----------------------------------
// fontSize
//-----------------------------------
@for $i from -1000 through 1000 {
  .fontSize-#{$i} {
    font-size: #{$i}px !important;
  }
}

//-----------------------------------
// formスタイルリセット
//-----------------------------------
@mixin formReset() {
  input,
  button,
  textarea,
  select {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
  }
}

//-----------------------------------
// 背景画像
//-----------------------------------
$PATH_IMG: "../img/";

@mixin bg-img-jpg($fileName) {
  background-image: url($PATH_IMG + $fileName + ".jpg");
  background-repeat: no-repeat;
  background-position: left top;
  @content;
}

@mixin bg-img-png($fileName) {
  background-image: url($PATH_IMG + $fileName + ".png");
  background-repeat: no-repeat;
  background-position: left top;
  @content;
}

@mixin bg-img-svg($fileName) {
  background-image: url($PATH_IMG + $fileName + ".svg");
  background-repeat: no-repeat;
  background-position: center;
  @content;
}

//-----------------------------------
// 三角形
//-----------------------------------
@mixin triangle($direction, $width, $height, $color) {
  width: 0;
  height: 0;
  border-style: solid;
  $valWidth: $width / 2;
  $valHeight: $height / 2;
  @if ($direction == top) {
    border-width: 0 $valWidth $height $valWidth;
    border-color: transparent transparent $color transparent;
  } @else if ($direction == left) {
    border-width: $valHeight $width $valHeight 0;
    border-color: transparent $color transparent transparent;
  } @else if ($direction == right) {
    border-width: $valHeight 0 $valHeight $width;
    border-color: transparent transparent transparent $color;
  } @else if ($direction == bottom) {
    border-width: $height $valWidth 0 $valWidth;
    border-color: $color transparent transparent transparent;
  }
}

//-----------------------------------
// アイコン
//-----------------------------------
@mixin icon {
  display: block;
  content: "";
  position: absolute;
}

//-----------------------------------
// アニメーション
//-----------------------------------
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
